import intl from 'react-intl-universal'
import { getLocale } from '@/services'

let localLangs = require
  .context('../../public/locales', false, /.*\.json/)
  .keys()
  .map(_ => /\.\/(.*)\.json/.exec(_)[1]) // 本地语言包

class Locale {
  constructor() {
    // 默认从localStorage中取lang配置，如若没有
    // 以浏览器语言为默认语言配置
    const DEFAULT_LOCALE = 'zh-CN'

    let lang = intl.determineLocale({
      localStorageLocaleKey: 'lang'
    })

    this.currentLocale = localLangs.includes(lang) ? lang : DEFAULT_LOCALE

    this.intl = intl
    this.locales = new Map()
  }

  async setLocale(lang) {
    let currentLocale = lang || this.currentLocale
    const { locales } = this

    if (locales.has(currentLocale)) {
      this.intlInit(currentLocale, locales.get(currentLocale))
    } else {
      const data = await getLocale(currentLocale)

      this.intlInit(currentLocale, data)

      locales.set(currentLocale, data) // 缓存语言
    }

    window.lang = currentLocale
    this.currentLocale = currentLocale
    window.localStorage.setItem('lang', currentLocale)
  }

  intlInit(currentLocale, data) {
    intl.init({
      currentLocale,
      locales: {
        [currentLocale]: data
      }
    })
  }
}

export default Locale
