import { dynamicWrapper, createRoutes } from '@/utils/route'

const routesConfig = app => [
  {
    path: '/oc/content/course',
    namespace: 'course',
    title: '教程管理',
    exact: true,
    component: dynamicWrapper(app, [import('./model/index')], () => import('./components/index'))
  },
  {
    path: '/oc/content/course/detail',
    title: '教程详情',
    namespace: 'courseDetail',
    component: dynamicWrapper(app, [import('./model/detail')], () =>
      import('./components/detail/index')
    )
  },
  {
    path: '/oc/content/course/add',
    title: '添加教程',
    namespace: 'courseAdd',
    component: dynamicWrapper(app, [import('./model/add')], () => import('./components/add/index'))
  },
  {
    path: '/oc/content/course/edit',
    title: '编辑教程',
    namespace: 'courseEdit',
    component: dynamicWrapper(app, [import('./model/edit')], () =>
      import('./components/edit/index')
    )
  }
]

export default app => createRoutes(app, routesConfig, false)
