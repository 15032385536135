// @ts-nocheck
import React, { Component } from 'react'

export const jsapi = () => {
  if (window.AMap) return Promise.resolve()

  const url =
    'https://webapi.amap.com/maps?v=1.4.15&key=91804c6d1feef380d160f2a06c0b4d76&callback=onLoad'
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.charset = 'utf-8'
  script.async = true
  script.defer = true
  script.src = url

  const p = new Promise(resolve => {
    window.onLoad = () => {
      return resolve()
    }
  })
  document.head.appendChild(script)
  return p
}

class Map extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false
    }
    if (typeof window !== 'undefined') {
      this.plugin = {}
      this.registeredEvents = []
      jsapi().then(() => {
        this.initMap()
        const { loaded } = this.state
        if (!loaded) {
          this.setState({
            loaded: true
          })
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    if ('events' in this.props) {
      this.initEvent(this.props)
    }
    if ('plugins' in this.props) {
      this.initPlugins(this.props)
    }

    if (this.props.name === 'address') {
      const { city = {}, hasMarker } = this.props
      const { province, name = '全国', isJump } = city
      if (!this.getPois) {
        this.getPois = this.props.getPois
      }
      if (name && isJump && name !== prevProps.city?.name) {
        this.gotoCity(name)
      }
      if (province && name && name !== prevProps.city?.name) {
        this.placeSearch(name, province)
      }
      if (hasMarker) {
        this.map.off('click', this.handleClick)
      } else {
        this.map.off('click', this.handleClick)
        this.map.on('click', this.handleClick)
      }
    }
  }

  componentWillUnmount() {
    if (!this.map) return
    if ('destroy' in this.map) {
      this.map.destroy()
    }
    window.AMap.event.removeListener(this.auto, 'select')
  }

  gotoCity = city => {
    this.map.setCity(city || '全国')
  }

  handleClick = e => {
    const getPois = this.getPois
    const p = [e.lnglat.lng, e.lnglat.lat]
    window.AMap.plugin(['AMap.Geocoder'], () => {
      let geocoder = new window.AMap.Geocoder({})
      geocoder.getAddress(p, (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
          // console.log(result)
          const addressCode = result.regeocode.addressComponent
          const address = result.regeocode.formattedAddress
          const region = [addressCode.province, addressCode.city, addressCode.district]
          getPois(p, address, region)
        }
      })
    })
  }

  placeSearch = (city = '全国', province = null) => {
    window.AMap.event.removeListener(this.auto, 'select')
    if (this.auto) {
      this.auto.setCity(city)
    } else {
      this.auto = new window.AMap.Autocomplete({
        input: 'tipAddress',
        city: city,
        // adcode: '330110',
        citylimit: true
      })
    }
    window.AMap.event.addListener(this.auto, 'select', e => this.searchClick(e, province, city))
  }

  searchClick = (e, province, city) => {
    if (e.poi && e.poi.district && e.poi.location) {
      const district = e.poi.district
      let region = [province, city, district.split(city)[1]]
      const p = [e.poi.location.lng, e.poi.location.lat]
      this.getPois(p, e.poi.name, region)
    }
  }
  // intoGps(info, getPois) {
  //   const { city, addressDetail } = info
  //   var keywords = addressDetail || city
  //   window.AMap.plugin('AMap.PlaceSearch', () => {
  //     var autoOptions = {
  //       city: city || '全国'
  //     }
  //     var placeSearch = new window.AMap.PlaceSearch(autoOptions)
  //     placeSearch.search(keywords, (status, result) => {
  //       // 搜索成功时，result即是对应的匹配数据
  //       if (status === 'complete' && result.info === 'OK') {
  //         getPois(result.poiList.pois)
  //       }
  //       var node = new window.PrettyJSON.view.Node({
  //         el: document.querySelector('#input-info'),
  //         data: result
  //       })
  //     })
  //   })
  // }

  // https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
  getRefs = el => {
    this.mapElement = el
  }

  initMap() {
    if (!this.map) {
      const { options = {} } = this.props
      const { getMap } = this.props
      this.map = new window.AMap.Map(this.mapElement, options)
      if (typeof getMap === 'function') {
        getMap(this.map)
      }
      this.initPlugins(this.props)
      this.initEvent(this.props)
      window.AMap.plugin(['AMap.ToolBar'], () => {
        this.map.addControl(
          new window.AMap.ToolBar({
            liteStyle: true
          })
        )
      })
    }
  }

  initPlugins(props) {
    if ('plugins' in props && this.map) {
      const { plugins } = props
      plugins.forEach(item => {
        const { name, options = {} } = item
        const { getPlugin, ...rest } = options
        if (!this.plugin[name]) {
          window.AMap.plugin(`AMap.${name}`, () => {
            this.plugin[name] = new window.AMap[name](rest)
            this.map.addControl(this.plugin[name])
            if (typeof getPlugin === 'function') {
              getPlugin(this.plugin[name])
            }
          })
        }
      })
    }
  }

  initEvent(props) {
    if ('events' in props && this.map) {
      const evs = Object.keys(props.events || {})
      evs.forEach(ev => {
        if (this.registeredEvents.indexOf(ev) === -1) {
          this.registeredEvents.push(ev)
          this.map.on(
            ev,
            (ev => {
              return (...args) => {
                if (props.events && ev in props.events) {
                  props.events[ev].apply(null, args)
                }
              }
            })(ev)
          )
        }
      })
    }
  }

  childrenWithProps() {
    const { children } = this.props
    return React.Children.map(children, child => {
      if (!child) return
      return React.cloneElement(child, {
        _map: this.map
      })
    })
  }

  render() {
    const { loaded } = this.state
    return (
      <div style={this.props.style || { width: '100%', height: '100%' }}>
        <div ref={this.getRefs} style={{ width: '100%', height: '100%' }} />
        {loaded ? this.childrenWithProps() : null}
      </div>
    )
  }
}

export default Map
