// @ts-nocheck
import React, { useEffect } from 'react'
import { TreeSelect } from 'antd'
import { useSelector, useDispatch } from 'dva'
import { jsapi } from '../Amap/Map'

const { SHOW_PARENT } = TreeSelect

const fetchCities = (level = 'country', subdistrict = 2, extensions = 'all') => {
  return jsapi().then(() => {
    const AMap = window.AMap
    return new Promise((resolve, reject) => {
      AMap.service('AMap.DistrictSearch', () => {
        new AMap.DistrictSearch({
          level,
          subdistrict,
          extensions
        }).search('中国', (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            resolve(result.districtList[0].districtList)
          } else {
            reject(result)
          }
        })
      })
    })
  })
}

const transform = (c, p = '') => {
  const parent = p ? `${p}/` : ''
  return c.map(cc => {
    const { name: title, districtList: cd } = cc
    const d = { title, value: parent + title }
    if (cd) {
      d.children = transform(cd, parent + title)
    }
    return d
  })
}

const transformOptions = c => {
  return c.map(cc => {
    const { name, districtList: cd } = cc
    const d = { label: name, value: name }
    if (cd) {
      d.children = transformOptions(cd)
    }
    return d
  })
}

const cityTransform = c => {
  return c.map(cc => {
    const { name: title, districtList: cd } = cc
    const d = { label: title, value: title }
    if (cd) {
      d.children = cityTransform(cd)
    }
    return d
  })
}

const getCity = async () => {
  let cities = []
  await fetchCities('country', 3).then(c => {
    cities = cityTransform(c)
  })
  return cities
}
const CityTree = props => {
  const dispatch = useDispatch()
  const global = useSelector(_ => _.global)
  useEffect(() => {
    let { cities } = global
    if (!cities || cities.length < 1) {
      fetchCities().then(c => {
        const cities = transform(c)
        const allCities = [
          {
            title: '全国',
            value: '全国',
            children: [...cities]
          }
        ]
        dispatch({
          type: 'global/updateState',
          payload: { cities: allCities }
        })
      })
    }
  })

  const defaultProps = {
    treeCheckable: true,
    showSearch: true,
    showCheckedStrategy: SHOW_PARENT
  }

  const { cities: treeData } = global
  const data = { ...defaultProps, ...props, treeData, loading: !treeData || treeData.length < 1 }
  return <TreeSelect {...data} />
}

export { fetchCities, transform, transformOptions, CityTree, getCity }
