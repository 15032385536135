import React, { memo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'dva'
import { useDeepCompareEffect } from 'common-hook'
import { Cascader } from 'antd'

const Belong = ({ ...props }) => {
  const dispatch = useDispatch()
  const { placeholder, onChangeBelong, defaultValue, vendorId = null, isNeedZero = false } = props
  const { permissionList, staffTree } = useSelector((_: any) => _.common)
  const [tree, setTree] = useState([])
  useEffect(() => {
    dispatch({
      type: 'common/getStaffTree'
    })
  }, [dispatch])
  useDeepCompareEffect(() => {
    const tar: any = []
    if (vendorId) {
      for (let i = 0, len = staffTree.length; i < len; i++) {
        if (staffTree[i].value === vendorId) {
          tar.push(staffTree[i])
          break
        }
      }
    }
    if (tar.length > 0) {
      setTree(tar)
    } else {
      setTree(staffTree)
    }
  }, [staffTree])

  const onChange = (value: any) => {
    const tar = isNeedZero ? 0 : ''
    const info: any = {}
    if (permissionList.vendor && permissionList.dealer && permissionList.agent) {
      // 厂家 代理商 经销商
      info.vendorId = value[0] || tar
      info.agentId = value[1] || tar
      info.dealerId = value[2] || tar
    } else if (permissionList.agent && permissionList.dealer) {
      // 代理商 经销商
      info.agentId = value[0] || tar
      info.dealerId = value[1] || tar
    } else if (permissionList.dealer) {
      //  经销商
      info.dealerId = value[0] || tar
    }
    onChangeBelong(info)
  }

  return (
    <Cascader
      options={tree}
      onChange={onChange}
      placeholder={placeholder}
      changeOnSelect
      defaultValue={defaultValue}
      style={{ minWidth: 250 }}
    />
  )
}

export default memo(Belong)
