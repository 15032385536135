import { createRoutes } from '@/utils/route'
import LoginLayout from '@/layouts/LoginLayout'
import BasicLayout from '@/layouts/BasicLayout'

// page
import Login from '@/page/Login/route'
import Home from '@/page/Home'
import Info from '@/page/Info/route'

// 设备
import Car from '@/pageEc/Car'
import Equ from '@/pageEc/Equ'
import Ota from '@/pageEc/Ota'

// 运营
import User from '@/pageOc/User'
import Notice from '@/pageOc/Notice'
import News from '@/pageOc/News'
import Course from '@/pageOc/Course'
import Check from '@/pageOc/Check'
import Book from '@/pageOc/Book'
import Card from '@/pageOc/Card'
import App from '@/pageOc/App'
import AppVersion from '@/pageOc/AppVersion'
import Branch from '@/pageOc/Branch'

// 系统
import Vendor from '@/pageSc/Vendor'
import Market from '@/pageSc/Market'
import Employee from '@/pageSc/Employee'
import Log from '@/pageSc/Log/route'
import Permission from '@/pageSc/Permission/route'

const routesConfig = app => [
  {
    path: '/login',
    component: LoginLayout,
    childRoutes: [Login(app)]
  },
  {
    path: '/',
    indexRoute: '/home',
    component: BasicLayout,
    childRoutes: [
      Home(app),
      Info(app),
      Car(app),
      Equ(app),
      Ota(app),

      User(app),
      Notice(app),
      News(app),
      Course(app),
      Check(app),
      Book(app),
      Card(app),
      App(app),
      AppVersion(app),
      Branch(app),

      Vendor(app),
      Market(app),
      Employee(app),
      Log(app),
      Permission(app)
    ]
  }
]

export default app => createRoutes(app, routesConfig, true)
