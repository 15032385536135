import { dynamicWrapper, createRoute } from '@/utils/route'

const routesConfig = app => ({
  path: '/oc/app/version',
  title: '版本管理',
  namespace: 'appVersion',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
})

export default app => createRoute(app, routesConfig)
