/* eslint-disable camelcase */
import React from 'react'
import { router } from 'dva'
import hot from 'dva-hot'
import { ConfigProvider, message } from 'antd'
import zh_CN from 'antd/es/locale/zh_CN'
import 'dayjs/locale/zh-cn'
import createRoutes from '@/routes'
import validateMessage from '@/config/validateMessage'

message.config({
  top: 65,
  duration: 3,
  maxCount: 1
})

const { Router } = router

function App({ history, app }) {
  return (
    <ConfigProvider locale={zh_CN} form={{ validateMessage }}>
      <Router history={history}>{createRoutes(app)}</Router>
    </ConfigProvider>
  )
}

export default hot.router(module)(App)
