import { dynamicWrapper, createRoutes, dynamicWrapperNoModel } from 'utils/route'

const routesConfig = app => [
  {
    exact: true,
    path: '/ec/equ/package',
    title: '固件管理',
    namespace: 'package',
    component: dynamicWrapperNoModel(() => import('./package/list'))
  },
  {
    exact: true,
    path: '/ec/equ/package/form',
    title: '固件表单',
    namespace: 'packageForm',
    component: dynamicWrapperNoModel(() => import('./package/form'))
  },

  {
    path: '/ec/equ/upgrade',
    title: '固件升级',
    exact: true,
    namespace: 'upgrade',
    component: dynamicWrapper(app, [import('./upgrade/list/model')], () => import('./upgrade/list'))
  },
  {
    path: '/ec/equ/upgrade/form',
    title: '固件升级表单',
    namespace: 'upgradeForm',
    component: dynamicWrapper(app, [import('./upgrade/form/model')], () =>
      import('./upgrade/form/index')
    )
  }
]

export default app => createRoutes(app, routesConfig, false)
