import { dynamicWrapper, createRoutes, dynamicWrapperNoModel } from 'utils/route'

const routesConfig = app => [
  {
    path: '/oc/content/book',
    title: '车辆说明书',
    namespace: 'book',
    exact: true,
    component: dynamicWrapperNoModel(() => import('./list'))
  },
  {
    path: '/oc/content/book/form',
    title: '表单',
    exact: true,
    namespace: 'bookForm',
    component: dynamicWrapper(app, [import('./form/model')], () => import('./form'))
  }
]

export default app => createRoutes(app, routesConfig, false)
