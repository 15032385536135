import { dynamicWrapper, createRoute } from '@/utils/route'

const routesConfig = app => ({
  path: '/oc/app',
  title: 'APP配置',
  namespace: 'appConfig',
  exact: true,
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
})

export default app => createRoute(app, routesConfig)
