import { dynamicWrapper, createRoutes, dynamicWrapperNoModel } from '@/utils/route'

const routesConfig = app => [
  {
    path: '/oc/user',
    title: '用户管理',
    namespace: 'user',
    exact: true,
    component: dynamicWrapper(app, [import('./list/model')], () => import('./list'))
  },
  {
    path: '/oc/user/detail',
    title: '用户详情',
    namespace: 'userDetail',
    component: dynamicWrapper(app, [import('./detail/model')], () => import('./detail'))
  },
  {
    path: '/oc/user/feedback',
    title: '反馈管理',
    namespace: 'feedback',
    exact: true,
    component: dynamicWrapper(app, [import('./feedback/model')], () => import('./feedback/index'))
  },
  {
    path: '/oc/user/feedback/type',
    title: '反馈类型',
    namespace: 'feedbackType',
    exact: true,
    component: dynamicWrapperNoModel(() => import('./feedbackType/list'))
  },
  {
    path: '/oc/user/feedback/type/form',
    title: '反馈类型表单',
    namespace: 'feedbackTypeForm',
    exact: true,
    component: dynamicWrapperNoModel(() => import('./feedbackType/form'))
  }
]

export default app => createRoutes(app, routesConfig, false)
