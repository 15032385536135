import React, { useEffect, memo } from 'react'
import { connect } from 'dva'
import styles from './index.module.less'

/** 高阶组件统一封装content显示部分
 *
 * 组件卸载时，根据配置的路由信息namespace的值，进行相应关联model的数据卸载
 *
 * @param {* JSX element} Comp 封装的组件
 * @param {* string} className 样式名称
 */

export const PageContent = (Comp: any, className?: any) =>
  connect((state, dispatch) => ({ state, dispatch }))(
    memo((props: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        return () => {
          // 非热更新页面卸载时，重置路由
          // @ts-ignore
          if (!window.isHmrChange) {
            const { routerData = {}, dispatch } = props
            const { namespace } = routerData
            if (namespace) {
              dispatch({ type: 'global/resetState', payload: { namespace } })
            }
          }
          // @ts-ignore
          window.isHmrChange = false // 热更新标志位重置
        }
      }, [])

      return (
        <div className={className || styles.container}>
          {props.children}
          <Comp {...props} />
        </div>
      )
    })
  )
