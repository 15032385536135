import { dynamicWrapper, createRoutes } from '@/utils/route'

const routesConfig = app => [
  {
    path: '/oc/content/news',
    namespace: 'news',
    title: '新闻管理',
    exact: true,
    component: dynamicWrapper(app, [import('./model/index')], () => import('./components/index'))
  },
  {
    path: '/oc/content/news/detail',
    title: '新闻详情',
    namespace: 'newsDetail',
    component: dynamicWrapper(app, [import('./model/detail')], () =>
      import('./components/detail/index')
    )
  },
  {
    path: '/oc/content/news/add',
    title: '添加新闻',
    namespace: 'newsAdd',
    component: dynamicWrapper(app, [import('./model/add')], () => import('./components/add/index'))
  },
  {
    path: '/oc/content/news/edit',
    title: '编辑新闻',
    namespace: 'newsEdit',
    component: dynamicWrapper(app, [import('./model/edit')], () =>
      import('./components/edit/index')
    )
  }
]

export default app => createRoutes(app, routesConfig, false)
