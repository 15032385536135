import React, { memo } from 'react'
import { useSelector } from 'dva'
import { MidTable } from 'common-mid'
import styles from './index.module.less'

// const permissionListDefault: any = {
//   detail: true,
//   edit: true,
//   del: true,
//   gps: true,
//   enable: true,
//   disable: true
// }

// {
//   title: '操作',
//   fixed: 'right',
//   key: 'operate',
//   isHide: true,
//   btnList: [
//     {
//       name: '车辆信息',
//       isShow: true,
//       type: 'dd',
//       onClick: (type: any, item: any) => {
//         console.log(type)
//         console.log(item)
//       }
//     }
//   ]
// }

export const Table = memo((props: any) => {
  const { permissionList } = useSelector((_: any) => _.common)
  const { current, pageSize, total, selection, onHandleAll, showPage = true, ...restProps } = props
  const btnProps = { type: 'primary', size: 'small' }
  const tableBtnList: any = {
    detail: { type: 'detail', name: '详情' },
    info: { type: 'info', name: '详情' },
    edit: { type: 'edit', name: '编辑' },
    del: { type: 'del', name: '删除' },
    gps: { type: 'gps', name: '位置' },
    able: { type: 'able', key: 'state', ableValue: 1, ableName: '启用', disAbleName: '禁用' },
    revoke: { type: 'revoke', key: 'state', value: 1, name: '撤销', isEqual: false }
  }
  const pageProps = {
    showPage,
    current,
    pageSize,
    total
  }
  const selectionProps = {
    name: '撤销',
    isShow: selection || false,
    onHandle: onHandleAll
  }
  return (
    <MidTable
      className={styles.table}
      tableBtnList={tableBtnList}
      permissionList={permissionList}
      btnProps={btnProps}
      pageProps={pageProps}
      selectionProps={selectionProps}
      {...restProps}
    />
  )
})
