/*
 * 网络请求参数全局配置
 */

const config = {
  timeout: 5000,
  baseURL: '/',
  withCredentials: true
}

export default config

// 登录接口排除token鉴权
export const exceptTokenUrls = ['/api/auth']

export const noNeedCodeUrls = [
  '/api/auth',
  '/base/export',
  '/base/importVehicle',
  '/output',
  '/manage/operate',
  '/upGrade/getOldVersion',
  '/equ/exportEqu',
  '/equ/importEqu',
  '/base/importVehicle',
  '/dvc/export',
  '/equ/export',
  '/back/export'
]

// 视频上传地址
export const videoUploadUrl = '/api/manager/file/video/upload'

// 图片上传地址
export const imageUploadUrl = '/api/manager/file/image/upload'

// 文件上传地址
export const fileUploadUrl = '/api/manager/file/upload'
