import axios from '@/utils/request'
import { getRandomStr } from '@/utils'
import { api } from 'utils/config'

const { manager, dvc } = api

// 获取系统的菜单权限，包括侧边栏和导航栏tab部分
export const getMenu = async () => axios.post(manager + `/permission/tree`)

// 获取语言包信息
export const getLocale = async lang => axios.get(`/locales/${lang}.json`)

// 获取当前系统版本号
export const getVersion = async () => axios.get(`/version.json?${getRandomStr(5)}`)

// 所有厂家
export const getAll = async (data = { all: 1 }) => axios.post(manager + `/factory`, { data })
// 所有厂家
export const allVendor = async (data = { all: 1 }) => axios.post(manager + `/factory`, { data })

// 厂家 代理商 经销商 树形结构
export const staffTree = async () => axios.post(manager + `/staff/tree`)

// 车辆-枚举
export const carEnums = async () => axios.get(dvc + `/enums`)

// 获取 厂家 名称logo
export const getLogo = async () => axios.post(manager + `/factory/info`)

// 图片存储的地址
export const getFileUrl = async () => axios.get(manager + `/file/host`)
