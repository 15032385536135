import 'core-js/stable'
import dva, { dynamic } from 'dva'
import { render } from 'react-dom'
import { createElement } from 'react'
import hot from 'dva-hot'
import createLoading from 'dva-loading'
import config from '@/config'
import './index.less'
import * as serviceWorker from './serviceWorker'

// -> 初始化
const app = dva({
  history: config.history
})

window.modelInitState = {} // 对应model的初始化状态

hot.patch(app)

// -> 插件配置
app.use(createLoading())
app.use({
  onError: config.exception.global,
  onReducer: reducer => (state, action) => {
    const namespace = action.type.split('/')[0]

    if (!window.modelInitState[namespace]) {
      window.modelInitState[namespace] = state[namespace]
    }

    const newState = reducer(state, action)
    return { ...newState }
  }
})

// 使用mock数据
require('@/__mocks__')

// -> 动态加载过渡组件
dynamic.setDefaultLoadingComponent(() => config.router.loading)

// -> 注册全局模型
app.model(require('@/models/global').default)
app.model(require('@/models/common').default)

app.router(require('./App').default)

// 重写app.router监听热更新事件变化
if (process.env.NODE_ENV === 'development') {
  const oldAppRouter = app.router
  app.router = router => {
    window.isHmrChange = true
    oldAppRouter(router)
  }
}

export const dynamicWrapperNoApp = (models, component) =>
  dynamic({
    app,
    models,
    component
  })

export const dynamicWrapperNoModel = component =>
  dynamic({
    app,
    component
  })

render(createElement(app.start()), hot.setContainer('#root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
