import { dynamicWrapper, createRoutes, dynamicWrapperNoModel } from '@/utils/route'

const routesConfig = app => [
  {
    path: '/ec/car',
    title: '车辆列表',
    exact: true,
    namespace: 'vehicleList',
    component: dynamicWrapper(app, [import('./Car/List/model')], () => import('./Car/List'))
  },
  {
    path: '/ec/car/form',
    title: '添加车辆',
    namespace: 'carForm',
    exact: true,
    component: dynamicWrapper(app, [import('./Car/Form/model')], () => import('./Car/Form'))
  },
  {
    path: '/ec/car/detail',
    title: '车辆详情',
    namespace: 'vehicleInfo',
    exact: true,
    component: dynamicWrapper(app, [import('./Car/Detail/model')], () => import('./Car/Detail'))
  },
  {
    path: '/ec/car/state',
    title: '车辆状态',
    namespace: 'carState',
    component: dynamicWrapper(app, [import('./Car/State/model')], () => import('./Car/State'))
  },

  {
    path: '/ec/car/model',
    title: '车型管理',
    exact: true,
    namespace: 'modelList',
    component: dynamicWrapperNoModel(() => import('./Model/list'))
  },
  {
    path: '/ec/car/model/form',
    title: '表单',
    exact: true,
    component: dynamicWrapper(app, [import('./Model/form/model')], () => import('./Model/form'))
  }
]
export default app => createRoutes(app, routesConfig, false)
