import React, { memo } from 'react'
import { MidBtnGroup } from 'common-mid'
import styles from './index.module.less'

export const BtnGroup = memo((props: any) => {
  const { list, children } = props
  return (
    <MidBtnGroup
      className={styles.group}
      list={list}
      children={children}
      property={{ size: 'large' }}
    />
  )
})

// const list = [
//   {
//     type: 'btn',
//     icon: <IconFont type="iconleading-in-01" />,
//     onClick: () => impHandle(1),
//     name: '导入车辆',
//     isHide: permissionList.imp,
//     property: { danger: true, type: 'text' }
//   },
//   {
//     type: 'content',
//     content: <input type="file" key="content" />
//   }
// ]
