import React, { memo } from 'react'
import { Layout, Avatar, Dropdown, Menu } from 'antd'
import { useDispatch } from 'dva'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  ExportOutlined
} from '@ant-design/icons'
import { storage as Storage } from 'common-screw'
import { Breadcrumb } from 'componentTs'

import styles from './index.module.less'

const Header = (props: any) => {
  const dispatch = useDispatch()
  const { collapsed, toggle, pathname } = props
  const signOut = () => {
    dispatch({
      type: 'global/signOut'
    })
  }
  const toInfo = () => {
    dispatch({
      type: 'common/handleJump',
      payload: { pathname: '/info' }
    })
  }
  const menu = () => (
    <Menu>
      <Menu.Item key="info" icon={<UserOutlined />} onClick={toInfo}>
        个人中心
      </Menu.Item>
      <Menu.Item key="signOut" icon={<ExportOutlined />} onClick={signOut}>
        退出
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Layout.Header className={styles.header} style={{ padding: 0 }}>
        {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: styles.trigger,
          // onClick: !collapsed && toggle,
          style: collapsed
            ? { transition: '2s ease all' }
            : { transform: 'translateX(250px)', transition: '2s ease all' }
        })} */}
        <div className={styles.right} style={collapsed ? {} : { width: 'calc(100% - 250px)' }}>
          {collapsed ? (
            <MenuUnfoldOutlined className={styles.trigger} />
          ) : (
            <MenuFoldOutlined className={styles.trigger} />
          )}
          <Breadcrumb pathname={pathname} />
          <Dropdown overlay={menu} placement="bottomCenter" className={styles.dropdown}>
            <div>
              {/* <Avatar size="large" src={AvatarPic} className={styles.avatar} /> */}
              <Avatar size="large" className={styles.avatar}>
                S
              </Avatar>
              {Storage.getItem('user').name}
            </div>
          </Dropdown>
        </div>
      </Layout.Header>
    </>
  )
}

export default memo(Header)
