import { dynamicWrapper, createRoutes } from '@/utils/route'

const routesConfig = app => [
  {
    path: '/oc/service/scheme',
    title: '套餐管理',
    namespace: 'flowCard',
    component: dynamicWrapper(app, [import('./Card/model')], () => import('./Card'))
  },
  {
    path: '/oc/service/fund',
    title: '交易记录',
    component: dynamicWrapper(app, [import('./Fund/model')], () => import('./Fund'))
  }
]

export default app => createRoutes(app, routesConfig, false)
