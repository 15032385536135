const api = 'api'

module.exports = {
  api: {
    manager: api + '/manager',
    dvc: api + '/device-manager/dvc',
    equ: api + '/device-manager/equ',
    staff: api + '/manager/staff',
    msg: api + '/manager/admin/msg',
    dev: api + '/device-manager'
  }
}
