import { dynamicWrapper, createRoute } from '@/utils/route'

const routesConfig = app => ({
  path: '/login',
  title: '登录',
  namespace: 'login',
  component: dynamicWrapper(app, [import('./model')], () => import('./'))
})

export default app => createRoute(app, routesConfig)
