import config from '@/config'

const { singleSpaActiveRule } = config

console.log('MICRO_DOMAIN', process.env)
const entry =
  process.env.NODE_ENV === 'development'
    ? '//device-simlink-v2.newtest.senthink.com'
    : '//device-simlink-v2.newtest.senthink.com'
// const entry = process.env.MICRO_DEVICE || '//simlinkv2-device.lierda.com' // 测试环境
// const entry = process.env.MICRO_DEVICE || '//device.simlink-demo.senthink.com' // 正式环境

const apps = permissions => [
  /**
   * name: 微应用名称 - 具有唯一性
   * entry: 微应用入口 - 通过该地址加载微应用
   * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
   * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
   */
  {
    name: 'vue',
    entry: entry,
    container: '#iframe',
    props: {},
    activeRule: location => singleSpaActiveRule.deviceService(location, permissions)
  }
]

export default apps
