// 路由懒加载效果示例
import React from 'react'
import { Spin } from 'antd'

const PageLoading = loading =>
  loading ? (
    <div
      style={{
        paddingTop: 100,
        textAlign: 'center'
      }}
    >
      <Spin size="large" />
    </div>
  ) : null

export default PageLoading
