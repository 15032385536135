import React, { useState, useEffect, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'dva'
import { Layout } from 'antd'
import { useTitle, useFavicon } from 'common-hook'
import { isNil } from 'common-screw'
import { AliveScope } from 'react-activation'
import { Sider, Header, Empty } from 'componentTs'
import config from 'config'
import styles from './styles/index.module.less'

const { singleSpaActiveRule } = config

const BasicLayout = ({ ...props }) => {
  const dispatch = useDispatch()
  const { pathHasPermissionList, showInfo, ablePathList } = useSelector(_ => _.global)

  const { history, routerData, location } = props
  const { childRoutes } = routerData
  const { pathname } = history.location
  const [collapsed, setCollapsed] = useState(true)

  useTitle(showInfo.name)
  useFavicon(showInfo.logo)

  useEffect(() => {
    // console.log('更新当前页面权限')
    const permissionsArr = pathHasPermissionList[pathname] || []
    let permissionList = {}
    for (const elem of permissionsArr) {
      permissionList[elem] = true
    }
    dispatch({
      type: 'common/updateState',
      payload: { permissionList }
    })
  }, [pathname, pathHasPermissionList, dispatch])

  const toggle = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  return (
    <>
      <Layout className={styles.layout}>
        <Sider toggle={toggle} collapsed={collapsed} pathname={pathname} />
        <Layout>
          <Header collapsed={collapsed} toggle={toggle} pathname={pathname} />
          <Layout className={styles.layoutScroll}>
            <div
              id="iframe"
              style={{
                display: singleSpaActiveRule.deviceService(location) ? '' : 'none'
              }}
            ></div>
            {ablePathList.includes(pathname) && childRoutes}
            {!isNil(ablePathList) && pathname && !ablePathList.includes(pathname) && <Empty />}
          </Layout>
        </Layout>
      </Layout>
    </>
  )
}

export default memo(props => (
  <AliveScope>
    <BasicLayout {...props} />
  </AliveScope>
))
