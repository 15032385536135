import { dynamicWrapper, createRoutes } from '@/utils/route'

const routesConfig = app => [
  {
    path: '/sc/market/agent',
    title: '代理商管理',
    component: dynamicWrapper(app, [import('./agent/model')], () => import('./agent'))
  },
  {
    path: '/sc/market/dealer',
    title: '经销商管理',
    component: dynamicWrapper(app, [import('./dealer/model')], () => import('./dealer'))
  }
]

export default app => createRoutes(app, routesConfig, false)
