import React from 'react'
import { routerRedux } from 'dva'
import { message } from 'antd'
import PageLoading from 'components/Loading/PageLoading'
import { createBrowserHistory as createHistory } from 'history'

// 应用配置 如请求格式，反回格式，异常处理方式，分页格式等
export default {
  htmlTitle: 'SIMLINK',
  history: createHistory(),

  // 微应用激活规则
  singleSpaActiveRule: {
    deviceService: (location, permissions) => {
      const reg = /^(\/bump|\/ella\/pageper|\/ella\/subsystem)/
      if (Array.isArray(permissions))
        return reg.test(location.pathname) && permissions.includes(location.pathname)
      return reg.test(location.pathname)
    }
  },

  // 异常处理机制
  exception: {
    // 全局异常处理

    global: (err, dispatch) => {
      const errName = err.name
      const errMsg = err.message
      // message.config({ maxCount: 1 })
      // 异常处理逻辑内容
      console.log(`###Global Exception(${errName}): ${errMsg}`)

      // 异常处理业务逻辑
      if (Number(err.status) === 401) {
        dispatch(routerRedux.push('/login'))
      }

      message.error(errMsg)
    }
  },

  // 路由加载效果
  router: {
    loading: <PageLoading loading />
  }
}
