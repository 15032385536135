import { dynamicWrapper, createRoutes } from 'utils/route'

const routesConfig = (app: any) => [
  {
    path: '/info',
    title: '个人中心',
    namespace: 'userInfo',
    component: dynamicWrapper(app, [import('./model')], () => import('./'))
  }
]

export default (app: any) => createRoutes(app, routesConfig, false)
