import { dynamicWrapperNoModel, createRoutes } from 'utils/route'

const routesConfig = app => [
  {
    path: '/sc/log',
    title: '操作日志',
    namespace: 'log',
    component: dynamicWrapperNoModel(() => import('./'))
  }
]

export default app => createRoutes(app, routesConfig, false)
