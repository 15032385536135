import React, { memo } from 'react'
import moment from 'moment'
import { MidForm } from 'common-mid'
import { Upload as BaseUpload, Message, RichText } from 'componentTs'
import Encrypt from 'config/crypto'
import styles from './index.module.less'

export const FormTd = memo((props: any) => {
  const { returnName, onBack, onSubmit, loading, setLoading, ...restProps } = props

  const formRules: any = {
    name: [
      { required: true },
      { min: 6, max: 26 },
      {
        pattern: /^(?!\d*$)[a-zA-Z\d]*$/,
        message: '请输入字母数字组合或纯字母'
      }
    ],
    password: [
      { required: true },
      { min: 6, max: 26 },
      {
        pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,
        message: '请输入数字和字母组合'
      }
    ],
    newPassword: [
      { min: 6, max: 26 },
      {
        pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,
        message: '请输入数字和字母组合'
      }
    ],
    confirmPassword: [
      { required: true },
      { min: 6, max: 26 },
      {
        pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,
        message: '请输入数字和字母组合'
      },
      ({ getFieldValue }: any) => ({
        validator(_: any, value: any) {
          if (!value || getFieldValue('newPassword') === value) {
            return Promise.resolve()
          }
          return Promise.reject(new Error('两次密码不一致,请检查'))
        }
      })
    ],
    select: [{ required: true }],
    date: [],
    // time: [{ required: true }],
    dateAndTime: [
      { required: true },
      {
        validator: (rule: any, value: any) => {
          if (value && value < moment().endOf('minute')) {
            return Promise.reject('时间已过')
          }
          return Promise.resolve()
        }
      }
    ],
    file: [{ required: true }],
    fileUrl: [{ required: true }],
    imgCrop: [{ required: true }],
    upload: [{ required: true }],
    cascader: [{ required: true }],
    timeRange: [{ required: true }],
    image: [],
    media: [],
    remark: [{ max: 100 }],
    phone: [{ required: true }, { pattern: /\d$/, message: '请输入正确的电话' }],
    telephone: [{ required: true }, { pattern: /^1\d{10}$/, message: '请输入正确的电话' }],
    email: [
      {
        pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
        message: '请输入正确的邮箱'
      }
    ],
    unRequired: [],
    selectNoRequired: [],
    undefined: [{ required: true }]
  }
  const formProps = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
    autoComplete: 'off',
    size: 'large',
    className: [styles.form, props.formLayout === 'center' && styles.formLayoutCenter]
  }
  const componentProps = {
    BaseUpload,
    Message,
    Encrypt,
    RichText
  }
  const btnProps = {
    submitName: '提交',
    returnName: returnName || '返回',
    isShowReturn: true,
    loading,
    setLoading,
    onBack,
    onSubmit
  }

  return (
    <MidForm
      formRules={formRules}
      componentProps={componentProps}
      formProps={formProps}
      btnProps={btnProps}
      {...restProps}
    />
  )
})
