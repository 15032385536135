import { dynamicWrapper, createRoutes } from '@/utils/route'

const routesConfig = app => [
  {
    path: '/oc/content/check',
    title: '自检内容管理',
    exact: true,
    namespace: 'checkContent',
    component: dynamicWrapper(app, [import('./List/model')], () => import('./List/components'))
  },
  {
    path: '/oc/content/check/form',
    title: '自检内容表单',
    namespace: 'checkForm',
    component: dynamicWrapper(app, [import('./List/form/model')], () => import('./List/form'))
  },

  {
    path: '/oc/content/check/detail',
    title: '查看自检内容',
    namespace: 'checkContentDetail',
    component: dynamicWrapper(app, [import('./List/model/detail')], () =>
      import('./List/components/detail')
    )
  },
  {
    path: '/oc/content/check/type',
    title: '自检手册分类',
    namespace: 'checkTypeList',
    component: dynamicWrapper(app, [import('./Type/list/model')], () => import('./Type/list'))
  }
]

export default app => createRoutes(app, routesConfig, false)
