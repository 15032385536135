/**
 * 数据模拟
 */
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

// 白名单数据源地址，绕开mock数据机制
const MockWhiteList = [`/api`, `/version.json`, `/locales`]

const mock = new MockAdapter(axios)

// 白名单注入
MockWhiteList.forEach(url => {
  const reg = new RegExp(url)

  mock.onAny(reg).passThrough()
})

// 模拟登陆接口
mock.onPost('/mock/login').reply(config => {
  const { username } = JSON.parse(config.data)
  const isAdmin = username === 'admin'

  const response = {
    status: 200,
    data: {
      token_type: 'Bearer', // token类型
      expires_in: 7862400, // 过期时间
      access_token: '1234',
      user: {
        id: isAdmin ? 1 : 2,
        name: isAdmin ? '管理员' : '测试人员'
      }
    }
  }

  return new Promise(resolve => {
    setTimeout(() => {
      resolve([200, { ...response }])
    }, 5000)
  })
})

// 获取用户信息，返回用户基本信息和权限
mock.onGet(/mock\/admin\/[1-9]/).reply(config => {
  const id = parseInt(config.url.replace(/^\S+\/(?=[1-9])/, ''))
  const isAdmin = id === 1

  const permissions = isAdmin
    ? [
        { id: 1, name: 'DataView' },
        { id: 2, name: 'test' },
        { id: 3, name: 'test/example1' },
        { id: 4, name: 'test/example2' },
        { id: 5, name: 'user/:id' },
        { id: 6, name: 'vue' }
      ]
    : [{ id: 1, name: 'DataView' }]
  const user = isAdmin ? { id, name: '管理员' } : { id, name: '测试人员' }

  return new Promise(resolve => {
    setTimeout(() => {
      resolve([200, { status: 200, data: { ...user, permissions } }])
    }, 2000)
  })
})
