import React, { memo } from 'react'
import { useSelector } from 'dva'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'
import { MidSearch } from 'common-mid'
import styles from './index.module.less'

export const Search = memo((props: any) => {
  const { permissionList } = useSelector((_: any) => _.common)
  const addProps = {
    isShow: permissionList.add,
    onClick: props.addHandle ? props.addHandle : () => props.addClick('add'),
    icon: <PlusOutlined />,
    name: props.addBtn || props.add || '添加'
  }
  const searchIcon = <SearchOutlined className="site-form-item-icon" style={{ color: '#3082F9' }} />
  return (
    <MidSearch className={styles.section} addProps={addProps} searchIcon={searchIcon} {...props} />
  )
})
