import { dynamicWrapper, createRoutes } from '@/utils/route'

const routesConfig = app => [
  {
    path: '/oc/content/notice',
    title: '消息推送',
    namespace: 'notice',
    exact: true,
    component: dynamicWrapper(app, [import('./model/index')], () => import('./components/index'))
  },
  {
    path: '/oc/content/notice/add',
    title: '添加消息',
    namespace: 'noticeAdd',
    component: dynamicWrapper(app, [import('./model/add')], () => import('./components/add'))
  },
  {
    path: '/oc/content/notice/edit',
    title: '编辑消息',
    namespace: 'noticeEdit',
    component: dynamicWrapper(app, [import('./model/edit')], () =>
      import('./components/edit/index')
    )
  },
  {
    path: '/oc/content/notice/detail',
    title: '消息详情',
    component: dynamicWrapper(app, [import('./model/index')], () =>
      import('./components/detail/index')
    )
  }
]

export default app => createRoutes(app, routesConfig, false)
