import { dynamicWrapper, createRoutes, dynamicWrapperNoModel } from 'utils/route'

const routesConfig = app => [
  {
    path: '/sc/employee/role',
    title: '角色管理',
    namespace: 'role',
    exact: true,
    component: dynamicWrapperNoModel(() => import('./role'))
  },
  {
    path: '/sc/employee/role/form',
    title: '表单',
    namespace: 'roleForm',
    component: dynamicWrapper(app, [import('./role/form/model')], () => import('./role/form'))
  },

  {
    path: '/sc/employee/account',
    title: '员工账号管理',
    namespace: 'account',
    exact: true,
    component: dynamicWrapperNoModel(() => import('./account/list'))
  },
  {
    path: '/sc/employee/account/form',
    title: '表单',
    namespace: 'accountForm',
    exact: true,
    component: dynamicWrapper(app, [import('./account/form/model')], () => import('./account/form'))
  }
]

export default app => createRoutes(app, routesConfig, false)
