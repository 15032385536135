import React, { useState, useEffect, memo, useRef } from 'react'
import {
  Form as AntdForm,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  Upload,
  Radio,
  Cascader
} from 'antd'
import moment from 'moment'
import { useSelector } from 'dva'
import classNames from 'classnames'
import { InboxOutlined } from '@ant-design/icons'
import { useDeepCompareEffect } from 'common-hook'
import { LooseObject } from 'common-screw'
import { throwConfirm, throwMessage } from 'utils'
import { Belong, ImgCrop, Upload as BaseUpload } from 'componentTs'
import Encrypt from 'config/crypto'
import styles from './index.module.less'

const { Item } = AntdForm
const { Option } = Select

const Form = ({ ...props }) => {
  const {
    type,
    initialValues = {},
    mustValues = null,
    formList,
    formLayout,
    onSubmit,
    onBack,
    loading,
    setLoading,
    showReturn = true,
    cancelText = '返回',
    propChangeValue = false,
    propChangeValueHandle
  } = props
  const { permissionList } = useSelector((_: any) => _.common)
  // const [loading, setLoading] = useState(false)
  const [colorValue, setColorValue] = useState('')
  const [formValues, setFormValues] = useState<LooseObject>(initialValues)
  const [belongInfo, setBelongInfo] = useState({})
  const [cascaderInfo, setCascaderInfo] = useState({})
  const FormRef: any = useRef(null)
  const [form] = AntdForm.useForm()

  let id = type === 'edit' ? initialValues.id : 0
  if (type === 'edit' && initialValues.time) {
    initialValues.time = moment(initialValues.time)
  }

  useEffect(() => {
    FormRef && FormRef.current && FormRef.current.resetFields()
  }, [id])
  useEffect(() => {
    if (initialValues?.color && !['#FF4444', '#108EE9', '#95F204'].includes(initialValues.color)) {
      form.setFieldsValue({
        color: 4
      })
      setColorValue(initialValues.color)
    }
    if (permissionList.vendor && permissionList.agent && permissionList.dealer) {
      // 厂家 代理商 经销商
      setBelongInfo({
        vendorId: initialValues.vendorId,
        agentId: initialValues.agentId,
        dealerId: initialValues.dealerId
      })
      form.setFieldsValue({
        belong: true
      })
    } else if (permissionList.agent && permissionList.dealer) {
      // 代理商 经销商
      setBelongInfo({
        agentId: initialValues.agentId,
        dealerId: initialValues.dealerId
      })
      form.setFieldsValue({
        belong: true
      })
    } else if (permissionList.dealer) {
      //  经销商
      setBelongInfo({
        dealerId: initialValues.dealerId
      })
      form.setFieldsValue({
        belong: true
      })
    }
  }, [initialValues])

  const onFinish = (values: any) => {
    setLoading(true)
    values = { ...values, ...belongInfo, ...cascaderInfo }
    if (type === 'edit' && initialValues.id) {
      values.id = initialValues.id
    } else if (type === 'edit' && initialValues.dvcId) {
      values.dvcId = initialValues.dvcId
      values.editType = initialValues.editType
    }

    if (values['time']) {
      values['time'] = values['time'].format('YYYY-MM-DD HH:mm:ss')
    }
    if (values['intelligentServingExpired']) {
      values['intelligentServingExpired'] =
        values['intelligentServingExpired'].format('YYYY-MM-DD') + ' 23:59:59'
    }
    if (values['upgradeTime']) {
      values['upgradeTime'] = values['upgradeTime'].format('YYYY-MM-DD HH:mm:ss')
    }
    if (values.file && values.firmwareId) {
      // 升级固件
      let formData: any = new FormData()
      formData.append('firmwareId', values.firmwareId)
      formData.append('all', values.all)
      formData.append('typeId', values.typeId)
      formData.append('file', values.file)
      formData.append('upgradeNow', values.upgradeNow)
      if (values.upgradeNow === '0') {
        formData.append('upgradeTime', values.upgradeTime)
      }

      onSubmit(formData, true)
      return
    } else if (values.file) {
      // 固件包
      let formData: any = new FormData()

      formData.append('factoryId', values.factoryId)
      formData.append('describe', values.describe || '')
      if (type === 'edit' && values.id) {
        formData.append('id', values.id)
      }
      if (values.file !== '已上传文件') {
        formData.append('file', values.file)
      }
      onSubmit(formData, true)
      return
    }
    if (
      values['password'] &&
      values['passwordAgain'] &&
      values['password'] !== values['passwordAgain']
    ) {
      throwConfirm({
        msg: '提交失败',
        data: '两次密码不一致,请检查后再提交'
      })
      setLoading(false)
      return
    }
    if (values['password']) {
      values.password = Encrypt(values.password)
    }
    if (values['oldPassword']) {
      values.oldPassword = Encrypt(values.oldPassword)
    }
    delete values.passwordAgain
    if (values['cascaderValue']) {
      delete values.cascaderValue
    }

    if (values['color'] === 4) {
      values.color = colorValue
    }
    if (values['relatedCascader']) {
      values.parentRelatedType = values['relatedCascader'][0]
      values.relatedType = values['relatedCascader'][1]
    }
    if (mustValues) {
      values.modelId = mustValues.id || 0
      if (values.vendorId === 0) {
        throwMessage({ msg: '请选择车辆归属' })
        setLoading(false)
        return
      }
    }
    if (values['manualType'] === '2') {
      // @ts-ignore
      const title = document.getElementsByClassName('ant-upload-list-item-name')[0]?.innerText || ''
      values.fileName = title
    }
    if (
      (values['loginBackground'] &&
        values['loginBackground'] === initialValues.loginBackground &&
        values['bgType'] !== initialValues.bgType) ||
      (values['bgType'] && !values['loginBackground'])
    ) {
      throwMessage({ msg: ` 请上传背景${values['bgType'] === '1' ? '图片' : '视频'}` })
      setLoading(false)
      return
    }
    onSubmit(values)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const disabledDate = (current: any) => {
    // Can not select days before today
    return current < moment().subtract(1, 'day')
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }
  const uploadProps = {
    maxCount: 1,
    beforeUpload: (file: any) => {
      form.setFieldsValue({
        file: file
      })
      return false
    }
  }

  const typeRules: any = {
    name: [
      { required: true },
      { min: 6, max: 26 },
      {
        pattern: /^(?!\d*$)[a-zA-Z\d]*$/,
        message: '请输入字母数字组合或纯字母'
      }
    ],
    password: [
      { required: true },
      { min: 6, max: 26 },
      {
        pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,
        message: '请输入数字和字母组合'
      }
    ],
    newPassword: [
      { min: 6, max: 26 },
      {
        pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,
        message: '请输入数字和字母组合'
      }
    ],
    select: [{ required: true }],
    time: [],
    // time: [{ required: true }],
    dateAndTime: [
      { required: true },
      {
        validator: (rule: any, value: any) => {
          if (value && value < moment().endOf('minute')) {
            return Promise.reject('时间已过')
          }
          return Promise.resolve()
        }
      }
    ],
    file: [{ required: true }],
    color: [{ required: true }],
    belong: [{ required: true }],
    cascader: [{ required: true }],
    fileUrl: [{ required: true }],
    imgCrop: [{ required: true }],
    image: [],
    media: [],
    remark: [{ max: 100 }],
    telephone: [{ required: true }, { pattern: /^1\d{10}$/, message: '请输入正确的电话' }],
    email: [
      {
        pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
        message: '请输入正确的邮箱'
      }
    ],
    unRequired: [],
    selectNoRequired: [],
    undefined: [{ required: true }]
  }
  const toItem = (itemProps: any, child: any) => {
    return <Item {...itemProps}>{child}</Item>
  }
  const onValuesChange = (vs: any, values: any) => {
    loading && setLoading(false)
    if (Object.keys(vs).includes('cascaderValue') || Object.keys(vs).includes('upgradeNow')) {
      return
    }
    setFormValues({ ...formValues, ...values })
    if (propChangeValue && Object.keys(vs).includes(propChangeValue)) {
      propChangeValueHandle(values)
    }
  }
  const colorChang = (e: any) => {
    form.setFieldsValue({
      color: 4
    })
    setColorValue(e.target.value)
  }
  const onChangeBelong = (value: any, onChange: any) => {
    setBelongInfo({ ...value })
    form.setFieldsValue({
      belong: true
    })
    if (onChange) {
      form.setFieldsValue({
        modelId: ''
      })
      onChange && onChange(value)
    }
  }
  const onChangeCascader = (e: any, valueList: any) => {
    const list: any = {}
    valueList.forEach((item: any, index: any) => {
      list[item] = e[index] ? e[index] : ''
    })
    setCascaderInfo({ ...list })
    form.setFieldsValue({
      ...list,
      device: '',
      softVersion: ''
    })
    setFormValues({ ...formValues, ...list, device: '', softVersion: '' })
  }
  return (
    <>
      <AntdForm
        name="basic"
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 12 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        size="large"
        className={classNames(styles.form, formLayout === 'center' && styles.formLayoutCenter)}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        ref={FormRef}
      >
        {formList &&
          formList.map((item: any) => {
            let { type, placeholder = `请输入${item.label}` } = item
            const itemProps = {
              key: item.name,
              label: item.label,
              name: item.name,
              rules: item.rules ? [...typeRules[type], ...item.rules] : typeRules[type]
            }
            let pickPlaceholder = `请选择${item.label}`
            if (type === 'password' || type === 'newPassword') {
              if (type === 'newPassword') placeholder = placeholder + '，若不修改 请留空'
              return toItem(
                itemProps,
                <Input.Password placeholder={placeholder} autoComplete="new-password" />
              )
            } else if (type === 'select' || type === 'selectNoRequired') {
              const optionList = item.optionList || []
              const keyValue = item.keyValue || ['id', 'value']
              if (item.isHide) {
                return ''
              }

              const returnItem = (
                <Item {...itemProps}>
                  <Select
                    placeholder={pickPlaceholder}
                    allowClear
                    disabled={item.disabled}
                    onChange={e => {
                      item.onChange && item.onChange(e)
                      item.clearValue && form.setFieldsValue({ [item.clearValue]: '' })
                    }}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                  >
                    {optionList.map((r: any) => {
                      // if (dependencies && current && r[type] !== current) return ''
                      return (
                        <Option key={r[keyValue[0]]} value={r[keyValue[0]]}>
                          {r[keyValue[1]]}
                        </Option>
                      )
                    })}
                  </Select>
                </Item>
              )
              if (item.dependencies) {
                const dependencies = item.dependencies
                return formValues[dependencies.name] === dependencies.value ? returnItem : null
              }
              return returnItem
            } else if (type === 'remark') {
              return toItem(itemProps, <Input.TextArea allowClear placeholder={placeholder} />)
            } else if (type === 'disabled') {
              return toItem(itemProps, <Input placeholder={placeholder} disabled />)
            } else if (type === 'color') {
              return toItem(
                itemProps,
                <Radio.Group optionType="button" className={styles.colorGroup}>
                  <Radio.Button value="#FF4444">
                    <span className={styles.dot} style={{ background: '#FF4444' }} /> #FF4444
                  </Radio.Button>
                  <Radio.Button value="#108EE9">
                    <span className={styles.dot} style={{ background: '#108EE9' }} />
                    #108EE9
                  </Radio.Button>
                  <Radio.Button value="#95F204">
                    <span className={styles.dot} style={{ background: '#95F204' }} />
                    #108EE9
                  </Radio.Button>
                  <Radio.Button value={4}>
                    <Input
                      type="color"
                      className={styles.inputColor}
                      onChange={colorChang}
                      value={colorValue}
                    />
                    自定义配色
                  </Radio.Button>
                </Radio.Group>
              )
            } else if (type === 'time') {
              const returnItem = toItem(
                itemProps,
                <DatePicker
                  // showTime
                  placeholder={pickPlaceholder}
                  format="YYYY-MM-DD"
                  // disabledDate={disabledDate}
                  style={{ width: '100%' }}
                />
              )
              if (item.dependencies) {
                const dependencies = item.dependencies
                return formValues[dependencies.name] === dependencies.value && returnItem
              }
              return returnItem
            } else if (type === 'dateAndTime') {
              const returnItem = toItem(
                itemProps,
                <DatePicker showTime placeholder={pickPlaceholder} disabledDate={disabledDate} />
              )
              if (item.dependencies) {
                const dependencies = item.dependencies
                return formValues[dependencies.name] &&
                  formValues[dependencies.name] === dependencies.value
                  ? returnItem
                  : null
              }
              return returnItem
            } else if (type === 'image') {
              return toItem(
                itemProps,
                <BaseUpload type="image" limits={item.limits} tip={item.extra} />
              )
            } else if (type === 'media') {
              const itemCom = toItem(
                itemProps,
                <BaseUpload type={item.mediaType} limits={item.limits} tip={item.extra} />
              )
              if (item.dependencies) {
                const dependencies = item.dependencies
                return formValues[dependencies.name] === dependencies.value && itemCom
              } else {
                return itemCom
              }
            } else if (type === 'fileUrl') {
              const itemCom = toItem(
                itemProps,
                <BaseUpload type="file" limits={item.limits} tip={item.extra} />
              )
              if (item.dependencies) {
                const dependencies = item.dependencies
                return formValues[dependencies.name] === dependencies.value && itemCom
              } else {
                return itemCom
              }
            } else if (type === 'imgCrop') {
              return item.isHide
                ? null
                : toItem(itemProps, <ImgCrop limits={item.limits} tip={item.tip} />)
            } else if (type === 'cascader') {
              // 级联选择
              return toItem(
                itemProps,
                <Cascader
                  options={item.optionList}
                  onChange={(e: any) => item.valueList && onChangeCascader(e, item.valueList)}
                />
              )
            } else if (type === 'belong') {
              let arr: any = []
              if (permissionList.vendor && permissionList.dealer && permissionList.agent) {
                // 厂家 代理商 经销商
                arr = [
                  initialValues.vendorId || '',
                  initialValues.agentId || '',
                  initialValues.dealerId || ''
                ]
              } else if (permissionList.agent && permissionList.dealer) {
                // 代理商 经销商
                arr = [initialValues.agentId || '', initialValues.dealerId || '']
              } else if (permissionList.dealer) {
                //  经销商
                arr = [initialValues.dealerId || '']
              }

              return toItem(
                itemProps,
                <Belong
                  placeholder="请选择归属"
                  defaultValue={arr}
                  isNeedZero={true}
                  vendorId={initialValues.vendorId}
                  onChangeBelong={(value: any) => onChangeBelong(value, item.onChange)}
                />
              )
            } else if (type === 'file') {
              const returnItem = (
                <Item {...itemProps}>
                  <Item valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                    <Upload.Dragger name={item.name} {...uploadProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">单击或拖动文件到此区域上传文件</p>
                      {initialValues.file && (
                        <p className="ant-upload-hint">文件已上传 {initialValues.fileName}</p>
                      )}
                    </Upload.Dragger>
                  </Item>
                </Item>
              )
              if (item.dependencies) {
                const dependencies = item.dependencies
                return formValues[dependencies.name] === dependencies.value && returnItem
              }
              return returnItem
            } else {
              const returnItem = toItem(
                itemProps,
                <Input
                  allowClear
                  placeholder={placeholder}
                  onChange={e => item.onChange && item.onChange(e.target.value)}
                />
              )
              if (item.dependencies) {
                const dependencies = item.dependencies
                return formValues[dependencies.name] === dependencies.value && returnItem
              }
              return returnItem
            }
          })}

        {props.children}
        <Item wrapperCol={{ span: 16 }} className={styles.buttonList}>
          <Space size="large">
            {showReturn && <Button onClick={onBack}>{cancelText}</Button>}
            <Button type="primary" htmlType="submit" loading={loading}>
              提交
            </Button>
          </Space>
        </Item>
      </AntdForm>
    </>
  )
}

export default memo(Form)
