import { dynamicWrapper, createRoute } from '@/utils/route'

const routesConfig = app => ({
  path: '/home',
  title: '首页-数据概览',
  namespace: 'dataView',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
})

export default app => createRoute(app, routesConfig)
