import React from 'react'
import { connect, router } from 'dva'
import { Row, Col } from 'antd'
import styles from './styles/login.module.less'

const { Switch } = router

const LoginLayout = props => {
  const { routerData } = props
  const { childRoutes } = routerData

  return (
    <div className={styles.container}>
      <Row className={styles['user-container']}>
        <Col className={styles.left} md={12} xl={16}></Col>
        <Col className={styles.right} span={24} md={12} xl={8}>
          <Switch>{childRoutes}</Switch>
        </Col>
      </Row>
    </div>
  )
}

export default connect(({ settings }) => ({ ...settings }))(LoginLayout)
