import React from 'react'
import { Helmet } from 'react-helmet'
import { dynamic, router } from 'dva'
import { storage } from 'common-screw'
import KeepAlive from 'react-activation'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import { getRandomStr } from '@/utils'
import { dynamicWrapperNoApp, dynamicWrapperNoModel } from 'index'

const { Route, Redirect, Switch } = router
// 路由映射表，记录缓存路由
window.cached_pathMap = {}

// 需要重置状态的路由信息
window.reset_pathMap = {}

/**
 * 生成动态组件
 * @param {*} app
 * @param {*} models
 * @param {*} component
 */
export const dynamicWrapper = (app, models, component) =>
  dynamic({
    app,
    models: () => models,
    component
  })

/**
 * 生成一组路由
 * @param {object} app dva应用app
 * @param {func} routesConfig 路由配置
 * @param {bool} 是否以Switch包裹
 */
export const createRoutes = (app, routesConfig, renderWithSwitch = false) => {
  const childRoutes = routesConfig(app).map(config => createRoute(app, () => config))
  return renderWithSwitch ? <Switch key={getRandomStr(4)}>{childRoutes}</Switch> : childRoutes
}

/**
 * 生成单个路由
 * @param {object} app dva创建的app
 * @param {func} routesConfig 路由配置
 */
export const createRoute = (app, routesConfig) => {
  const {
    component: Comp,
    path,
    indexRoute,
    title,
    exact,
    withHeader, // withHeader 是否开启PageHeader
    alive,
    ...otherProps
  } = routesConfig(app)

  if (alive) {
    window.cached_pathMap[path] = true
  }
  const titleName = storage.getItem('titleName')
  const routeProps = {
    key: path || getRandomStr(4),

    render: props => {
      const key = window.reset_pathMap[path] || path
      const childComp = (
        <>
          {withHeader && <PageHeaderWrapper />}
          <Comp key={key} alive={alive} routerData={otherProps} path={path} {...props}>
            <Helmet>
              <title>{titleName}</title>
            </Helmet>
          </Comp>
        </>
      )
      return alive ? (
        <KeepAlive id={path} name={path}>
          {childComp}
        </KeepAlive>
      ) : (
        childComp
      )
    },
    ...(path && { path }),
    ...(exact && { exact })
  }

  if (indexRoute) {
    return [
      <Redirect key={path + '_redirect'} exact from={path} to={indexRoute} />,
      <Route {...routeProps} />
    ]
  }

  return <Route {...routeProps} />
}
export { dynamicWrapperNoApp, dynamicWrapperNoModel }
