import { dynamicWrapper, createRoutes } from '@/utils/route'

const routesConfig = app => [
  {
    path: '/sc/vendor',
    title: '厂家管理',
    exact: true,
    component: dynamicWrapper(app, [import('./list/model')], () => import('./list'))
  },
  {
    path: '/sc/vendor/form',
    title: '厂家表单',
    exact: true,
    component: dynamicWrapper(app, [import('./form/model')], () => import('./form'))
  },

  {
    path: '/sc/vendor/plan',
    title: '产品方案',
    exact: true,
    component: dynamicWrapper(app, [import('./plan/model')], () => import('./plan'))
  },
  {
    path: '/sc/vendor/plan/form',
    title: '产品方案表单',
    component: dynamicWrapper(app, [import('./planForm/model')], () => import('./planForm'))
  },

  {
    path: '/sc/vendor/app',
    title: 'APP配置',
    component: dynamicWrapper(app, [import('./app/model')], () => import('./app'))
  }
]

export default app => createRoutes(app, routesConfig, false)
