import { dynamicWrapper, createRoutes } from 'utils/route'

const routesConfig = app => [
  {
    path: '/ec/equ',
    title: '设备列表',
    exact: true,
    component: dynamicWrapper(app, [import('./list/model')], () => import('./list'))
  },
  {
    path: '/ec/equ/form',
    title: '设备表单',
    exact: true,
    component: dynamicWrapper(app, [import('./form/model')], () => import('./form'))
  }
]
export default app => createRoutes(app, routesConfig, false)
