import { dynamicWrapper, createRoutes } from '@/utils/route'

const routesConfig = app => [
  {
    path: '/sc/permission',
    title: '权限管理',
    namespace: 'permission',
    component: dynamicWrapper(app, [import('./model')], () => import('./index'))
  }
]
export default app => createRoutes(app, routesConfig, false)
