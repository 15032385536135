// 全局model模型
import hot from 'dva-hot'
import { routerRedux } from 'dva'
import { storage as Storage, toMenuData } from 'common-screw'
import Locale from '@/config/locale'
import config from '@/config'
import startQiankun, { registerApp } from '@/micro'
import { getVersion, getMenu, getLogo } from 'services'

const localeIntl = new Locale()
const { history } = config

export default hot.model(module)({
  namespace: 'global',

  state: {
    cities: [],
    user: {},
    intl: localeIntl.intl,
    menuList: [],
    pathHasPermissionList: {},
    breadcrumbList: {},
    ablePathList: [],
    showInfo: { name: 'SIMLINK 出行平台', logo: '' }
  },

  subscriptions: {
    setup({ history, dispatch }) {
      const { pathname } = history.location
      if (pathname !== '/login' && Storage.getItem('token')) {
        dispatch({ type: 'getMenuData' })
        dispatch({ type: 'common/getAllVendor' })
      }
      dispatch({ type: 'common/getFileUrl' })
      // 没有登录回到登录页
      if (!Storage.getItem('token')) {
        dispatch(routerRedux.replace('/login'))
      }

      const currentLocale = localeIntl.currentLocale
      dispatch({ type: 'getLogo' })
      dispatch({ type: 'setLocale', payload: { currentLocale } })

      return history.listen(({ pathname }) => {
        dispatch({ type: 'getVersion' })
      })
    }
  },

  effects: {
    *getMenuData({ payload }, { call, put }) {
      const { data } = yield call(getMenu)
      const { pathname } = history.location
      const IS_FROM_LOGIN = payload

      const {
        menuList, // 菜单数据
        pathHasPermissionList, // 路由所拥有功能权限
        breadcrumbList, // 面包屑数据
        ablePathList, // 可访问页面
        fistPath // 第一个有效路由
      } = toMenuData(data)
      // console.log('menuList', menuList)
      // console.log('pathHasPermissionList', pathHasPermissionList)
      // console.log('breadcrumbList', breadcrumbList)
      // console.log('ablePathList', ablePathList)
      // console.log('fistPath', fistPath)
      ablePathList.push('/info')

      yield put({
        type: 'updateState',
        payload: {
          menuList,
          pathHasPermissionList,
          ablePathList,
          breadcrumbList
        }
      })

      registerApp(ablePathList) // 权限加载完成后，微前端应用载入
      startQiankun()

      const newPathname = fistPath === '/ec' ? '/ec/car' : fistPath
      // 登录时重新计算权限页
      if (IS_FROM_LOGIN) {
        const redirectRoute = newPathname
        window.location.href = redirectRoute // 重定向以消除微应用注册信息
      } else if (pathname === '/' || pathname === '/login') {
        yield put(
          routerRedux.push({
            pathname: newPathname
          })
        )
      }
    },
    *getLogo({ payload }, { put, call, select }) {
      const { data } = yield call(getLogo)
      const { showInfo } = yield select(_ => _.global)
      const { fileServerUrl } = yield select(_ => _.common)
      const name = data.name ? data.name : showInfo.name
      Storage.setItem('titleName', name)
      yield put({
        type: 'updateState',
        payload: {
          showInfo: {
            name,
            logo: data.logo ? fileServerUrl + data.logo : showInfo.logo
          }
        }
      })
    },
    *getVersion({ payload }, { put, call }) {
      const { NODE_ENV, VERSION } = process.env
      if (NODE_ENV === 'production') {
        const { data } = yield call(getVersion)
        if (VERSION !== data.version) {
          window.location.reload()
        }
      }
    },

    // 还原对应model的初始状态
    *resetState({ payload }, { put, call }) {
      const { namespace } = payload
      // console.log('----------------')
      // console.log('初始化', namespace)
      yield put.resolve({
        type: `${namespace}/resetState`,
        payload: window.modelInitState[namespace]
      })
    },

    *signOut({ payload }, { put }) {
      Storage.removeItem('token')
      Storage.removeItem('user')

      // yield put({
      //   type: 'common/handleJump',
      //   payload: {
      //     pathname: '/login'
      //   }
      // })

      yield put(routerRedux.replace('/login'))
    },

    *setLocale({ payload }, { put, call }) {
      const { currentLocale } = payload

      yield call(() => localeIntl.setLocale(currentLocale))

      yield put({ type: 'updateState', payload: { currentLocale } })
    }
  },

  reducers: {
    userInfo(state, { payload }) {
      const { user, token, vendorId } = payload

      Storage.setItem('token', `${token}`)
      Storage.setItem('user', { name: user })
      Storage.setItem('vendorId', vendorId)
      return { ...state, user: { name: user } }
    },

    updateState(state, { payload }) {
      return {
        ...state,
        ...payload
      }
    }
  }
})
