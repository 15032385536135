import React, { useState, memo, useEffect } from 'react'
import { router, useSelector, useDispatch } from 'dva'
import { Layout } from 'antd'
import { isNil } from 'common-screw'
import classNames from 'classnames'
import { IconFont } from 'componentTs'
import styles from './index.module.less'

const { Link } = router

const Sider = ({ ...props }) => {
  const dispatch = useDispatch()
  const { menuList, showInfo } = useSelector(({ global }: any) => global)
  const { collapsed, pathname, toggle } = props
  const pathSnippets = pathname.split('/').filter((i: any) => i)
  const [hoverList, setHoverList]: any = useState(null)
  const iconEnter = (item?: any) => {
    setHoverList(item.path === '/home' ? null : item)
    item.path !== '/home' && collapsed && toggle()
    item.path === '/home' && !collapsed && toggle()
  }
  const jumpHome = (isHome?: any) => {
    isHome &&
      dispatch({
        type: 'common/handleJump',
        payload: { pathname: '/home' }
      })
  }

  // const boxLeave = (e: any) => {
  //    console.log(e.clientX)
  //   if (e.clientX > 85 + 250) {
  //     setHoverList(null)
  //     hoverList.path !== '/home' && !collapsed && toggle()
  //   }

  // }
  const mouseMove = (e: any) => {
    // console.log(e.clientX)
    if (e.clientX > 85 + 250) {
      hoverList.path !== '/home' && !collapsed && toggle()
      setTimeout(setHoverList(null), 500)
    }
  }
  useEffect(() => {
    if (!isNil(hoverList)) {
      window.addEventListener('mousemove', mouseMove)
    } else {
      window.removeEventListener('mousemove', mouseMove)
    }
    return () => {
      // useEffect卸载时解绑
      window.removeEventListener('mousemove', mouseMove)
    }
  }, [hoverList])

  return (
    <>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={styles.sider}
        // onMouseLeave={boxLeave}
      >
        <div
          className={styles.logo}
          style={showInfo.logo ? { backgroundImage: `url(" ${showInfo.logo}")` } : {}}
        />
        <div className={styles.menu}>
          {menuList.map((item: any) => {
            const active = '/' + pathSnippets[0] === item.path
            return (
              <div
                key={item.path}
                className={classNames(styles.menu1, active && styles.active)}
                onMouseEnter={() => iconEnter(item)}
                onClick={() => jumpHome(item.path === '/home')}
              >
                <IconFont type={`icon-${item.icon}${active ? 1 : 2}`} />
                {item.name}
              </div>
            )
          })}
        </div>
      </Layout.Sider>
      {/* {hoverList && !collapsed && ( */}
      <div
        className={styles.hoverBox}
        style={{
          visibility: hoverList && !collapsed ? 'visible' : 'hidden',
          opacity: hoverList && !collapsed ? '1' : '0',
          left: hoverList && !collapsed ? 85 : -165
        }}
      >
        <div className={styles.title}>{hoverList?.name}中心</div>
        <div className={styles.content}>
          {hoverList &&
            hoverList.children?.map((item: any) => {
              const children = item.children
              return (
                <div key={item.path}>
                  <h4>{item.name}</h4>
                  {children?.map((_: any) => {
                    return (
                      <Link
                        to={_.path}
                        key={_.path}
                        className={pathname === _.path ? styles.active : ''}
                      >
                        {_.name}
                      </Link>
                    )
                  })}
                </div>
              )
            })}
        </div>
      </div>
      {/* )} */}
    </>
  )
}

export default memo(Sider)
