import React, { memo } from 'react'
import { useSelector, router } from 'dva'
import { IconFont } from 'componentTs'
import { MidBreadcrumb } from 'common-mid'
import styles from './index.module.less'

const { Link } = router
export const Breadcrumb = memo((props: any) => {
  const { breadcrumbList, ablePathList } = useSelector(({ global }: any) => global)
  const { pathname } = props
  const breadProps = {
    separator: '>'
  }
  const baseProps = {
    isShowIcon: false,
    homeUrl: '/home',
    homeIcon: 'icon-menu-sy1'
  }
  const specialList = {
    noJumpList: {
      form: '表单',
      info: '个人中心',
      add: '添加',
      edit: '编辑'
    },
    jumpList: {
      ec: null,
      oc: null,
      sc: null,
      market: '/sc/market/agent',
      employee: '/sc/employee/role',
      content: '/oc/content/notice',
      card: '/oc/service/scheme'
    },
    noShowList: ['home']
  }
  const componentProps = {
    Link,
    IconFont
  }

  return (
    <MidBreadcrumb
      className={styles.antdBread}
      componentProps={componentProps}
      breadProps={breadProps}
      baseProps={baseProps}
      specialList={specialList}
      pathname={pathname}
      pathInfo={breadcrumbList}
      pathShowList={ablePathList}
    />
  )
})
