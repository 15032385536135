import { routerRedux } from 'dva';
import { allVendor,staffTree ,carEnums,getFileUrl} from 'services';
import {getCity} from 'components'
import {getTreeNodesStaff} from 'utils'
import type { ModelType } from './connect';


const CommonModel: ModelType = {
  namespace: 'common',
  state: {
    permissionList: {},
    vendorList: {},
    vendorMap: [],
    vendorMaps: [],
    staffTree:[],
    carEnumsList:[],
    cityTree:[],
    fileServerUrl:'http://product-simlink-platform.oss.senthink.com/'
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *handleJump({ payload = {} }, { call, put }) {
      const { pathname } = payload;
      yield put(
        routerRedux.push({
          pathname: pathname,
          state: { ...payload },
        })
      );
    },
    *getStaffTree({ payload }, { call, put }) {
      const { data } = yield call(staffTree);
      yield put({
        type: 'updateState',
        payload: {
          staffTree:getTreeNodesStaff(data)
        },
      });
    },
    *getAllVendor({ payload }, { call, put ,select}) {
      const { vendorMap:oldVendorMap} = yield select((_:any) => _.common)
      
      if(oldVendorMap.length>0){return}
      const { data } = yield call(allVendor);

      const records =data||[]
      let vendorList: any = {};
      let vendorMap: any = [];
      let vendorMaps: any = [];
      records.forEach((item: any) => {
        vendorList[item.id] = item.name;
        vendorMap.push({
          id: item.id,
          value: item.name,
        });
        vendorMaps.push({
          value: item.id,
          text: item.name,
        });
      });
      yield put({
        type: 'updateState',
        payload: {
          vendorList,
          vendorMap,
          vendorMaps
        },
      });
    },
    *getCarEnums({ payload }, { call, put }) {
      const { data } = yield call(carEnums)
      yield put({
        type: 'updateState',
        payload: {
          carEnumsList: data
        }
      })
    },
    
    *getFileUrl({ payload }, { call, put }) {
      const { data } = yield call(getFileUrl)
      yield put({
        type: 'updateState',
        payload: {
          fileServerUrl: data.host +'/'
        }
      })
    },
    *getCity({ payload }, { call, put }) {
      let cityTree:any=yield call(getCity)
      yield put({
        type: 'updateState',
        payload: {
          cityTree
        }
      })
    },
    
  },
};

export default CommonModel;
