import React, { useState, useEffect } from 'react'
import ReactEcharts from 'echarts-for-react'
import { Empty } from 'antd'
import { deepClone, isNil } from 'common-screw'

const DEFAULT_OPTION: any = {
  line: {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      left: 'left',
      icon: 'circle',
      itemGap: 20
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisTick: { show: false },
      splitLine: { show: false },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#FAFBFC'
        }
      },
      axisLabel: {
        color: '#B0B7C3',
        fontSize: 12,
        lineHeight: 16,
        formatter: (value: any, idx: any) => value.replace(' ', '\n')
      },
      data: []
    },
    yAxis: [
      {
        type: 'value',
        scale: true,
        axisTick: { show: false },
        splitLine: { show: false },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#FAFBFC'
          }
        },
        axisLabel: {
          color: '#B0B7C3',
          fontSize: 12
        }
      },
      {
        type: 'value',
        scale: true,
        axisTick: { show: false },
        splitLine: { show: false },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#FAFBFC'
          }
        },
        axisLabel: {
          color: '#B0B7C3',
          fontSize: 12,
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'SOC(%)',
        type: 'line',
        yAxisIndex: 1,
        smooth: 0.6,
        symbol: 'none',
        lineStyle: {
          color: '#377DFF',
          width: 3
        },
        data: []
      },
      {
        name: '电压(V)',
        type: 'line',

        smooth: 0.6,
        symbol: 'none',
        lineStyle: {
          color: '#38CB89',
          width: 3
        },
        data: []
      },
      {
        name: '电流(A)',
        type: 'line',
        smooth: 0.6,
        symbol: 'none',
        lineStyle: {
          // color: '#38CB89',
          width: 3
        },
        data: []
      }
    ]
  },
  bar: {
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: '3%',
      right: '4%',
      // bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      axisTick: {
        show: false,
        alignWithLabel: true
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#FAFBFC'
        }
      },
      axisLabel: {
        color: '#B0B7C3',
        fontSize: 12
      },
      data: []
    },
    dataZoom: [
      {
        show: false,
        start: 0,
        end: 100
      }
    ],
    yAxis: {
      type: 'value',
      axisTick: { show: false },
      splitLine: { show: false },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#FAFBFC'
        }
      },
      axisLabel: {
        color: '#B0B7C3',
        fontSize: 12
      }
    },
    series: [
      {
        name: '里程 KM',
        type: 'bar',
        barWidth: 30,
        itemStyle: {
          color: '#377DFF'
        },
        data: []
      }
    ]
  }
}

const Chart = (props: any) => {
  const { dataList = {}, type } = props
  const [option, setOption] = useState({})

  useEffect(() => {
    if (
      !dataList.categories ||
      !dataList.data ||
      dataList.categories.length === 0 ||
      dataList.data.length === 0
    )
      return
    let newOption: any = isNil(option) ? deepClone(DEFAULT_OPTION[type]) : deepClone(option)
    if (type === 'line') {
      newOption.xAxis.data = dataList.categories
      newOption.series[0].data = dataList.data[0]
      newOption.series[1].data = dataList.data[1]
      newOption.series[2].data = dataList.data[2]
    } else if (type === 'bar') {
      newOption.xAxis.data = dataList.categories
      newOption.series[0].data = dataList.data
      newOption.dataZoom[0].show = dataList.data.length > 10
    }
    setOption(newOption)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList, type])

  return (
    <>
      {!dataList.categories ||
      !dataList.data ||
      dataList.categories.length === 0 ||
      dataList.data.length === 0 ? (
        <Empty style={{ paddingTop: 100, height: 450 }} />
      ) : (
        <ReactEcharts option={option} notMerge={true} lazyUpdate={true} style={{ height: 450 }} />
      )}
    </>
  )
}

export default Chart
