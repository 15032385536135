import { createRoutes, dynamicWrapperNoModel } from 'utils/route'

const routesConfig = app => [
  {
    path: '/oc/service/branch',
    title: '服务网点',
    namespace: 'network',
    exact: true,
    component: dynamicWrapperNoModel(() => import('./list'))
  },
  {
    path: '/oc/service/branch/form',
    title: '表单',
    exact: true,
    namespace: 'networkForm',
    component: dynamicWrapperNoModel(() => import('./form'))
  }
]

export default app => createRoutes(app, routesConfig, false)
