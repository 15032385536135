import React from 'react'
import moment from 'moment'
import { Popover, message, Modal } from 'antd'
import { returnJson } from '@/utils/rules'

/**
 * 事件停止被触发N秒后才会再次触发回调
 *
 * @param {Function} func - 回调执行函数
 * @param {String} wait - 触发间隔
 * @param {Boolean} immediate - 是否延时执行
 */
function debounce(func, wait = 500, immediate = false) {
  let timeout
  return function transform() {
    const context = this
    const args = arguments
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait || 0)
    if (callNow) func.apply(context, args)
  }
}

// 装饰器按钮限流封装，异步事件返回之前
function lock(target, key, desc) {
  const fn = desc.value

  desc.value = async function descValue() {
    if (this.$lock) return
    this.$lock = true

    await fn.apply(this).finally(() => {
      this.$lock = false
    })
    return target
  }
}

/**
 * 保证函数只执行一次
 *
 * @param {Fucntion} fn  待执行函数
 * @param {Object} context 上下文环境
 */
function once(fn, context) {
  let result

  return () => {
    if (fn) {
      result = fn.apply(context || this, arguments)
      fn = null
    }

    return result
  }
}

/**
 * 对象深拷贝
 *
 * @param {Object} obj 待拷贝对象
 */
function deepClone(obj) {
  if (typeof obj === 'object') {
    let newObj = obj.constructor === 'Array' ? [] : {}
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        typeof obj[key] === 'object'
          ? (newObj[key] = deepClone(obj[key]))
          : (newObj[key] = obj[key])
      }
    }
    return newObj
  }
  return obj
}

/**
 * @description ### 生成随机字符串
 *
 * @param {number} len 字符串长度
 */
function getRandomStr(len) {
  let str = ''
  for (
    ;
    str.length < len;
    str += Math.random()
      .toString(36)
      .substr(2)
  );
  return str.substr(0, len)
}

/**
 * async/await统一错误处理
 *
 * @param {Promise} promise
 * @returns {Promise} [ data, undefined ]
 * @returns {Promise} [ undefined, Error ]
 */
const asyncHandler = promise => {
  return promise.then(data => [data, undefined]).catch(error => Promise.resolve([undefined, error]))
}

// 时间戳转换
const formatDate = (date, format) => {
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S+': date.getMilliseconds()
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length))
  }
  Object.keys(o).forEach(k => {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
      )
    }
  })
  return format
}

/**
 * 时间范围
 * @param {string} type
 */
const getTimeRange = type => {
  if (type === 'lastSevenDays') {
    return [moment().add(-7, 'days'), moment().add(0, 'days')]
  }
  if (type === 'lastOneMonth') {
    return [moment().add(-30, 'days'), moment().add(0, 'days')]
  }
  if (type === 'lastThreeMonths') {
    return [moment().add(-90, 'days'), moment().add(0, 'days')]
  }
}

/**
 * 图表显示数据处理
 * @param {Array} data
 */
const timeData = data => {
  let xdata = []
  let ydata = []
  let allData = []
  for (let i = 0; i < data.length; i++) {
    xdata.push(data[i].countDate || data[i].vendorName)
    ydata.push(data[i].count)
  }
  allData.push(xdata, ydata)
  return allData
}

/**
 * 列表内容显示过长处理
 * @param {string} text
 * @param {number} length
 */
const fixColumn = (text, length = 16) => {
  if (typeof text === 'string') {
    if (text.length < length) {
      return text
    }
    return (
      <Popover
        content={<div style={{ maxWidth: 500, wordWrap: 'break-word' }}>{text}</div>}
        placement="topLeft"
        trigger="hover"
        // overlayStyle={expandTooltip}
        color="#fff"
      >
        {`${text.substring(0, length)}...`}
      </Popover>
    )
  }
  return null
}

// 时间戳转时间
const add0 = m => {
  return m < 10 ? '0' + m : m
}

const timesTampsToData = timesTamps => {
  if (timesTamps == null) {
    return ''
  }
  let time = new Date(timesTamps)
  let y = time.getFullYear()
  let m = time.getMonth() + 1
  let d = time.getDate()
  let h = time.getHours()
  let mm = time.getMinutes()
  let s = time.getSeconds()
  return `${y}-${add0(m)}-${add0(d)} ${add0(h)}:${add0(mm)}:${add0(s)}`
}

// 统一返回数据处理
const throwMessage = data => {
  // message.config({
  //   maxCount: 1
  // })
  if (data.code === '8001') {
    message.success(`${data.msg}`)
  } else if (data.code === '1111') {
    message.loading(`${data.msg}`)
  } else {
    message.error(`${data.msg}`)
  }
}

// 统一返回数据处理
const throwConfirm = data => {
  Modal.warning({
    title: data.msg,
    content: data.data
  })
}

// 当前时间的前后一天，n可以为正负数
const GetDateStr = AddDayCount => {
  let dd = new Date()
  dd.setDate(dd.getDate() + AddDayCount) // 获取AddDayCount天后的日期
  let y = dd.getFullYear()
  let m = dd.getMonth() + 1 // 获取当前月份的日期
  m = m < 10 ? '0' + m : m
  let d = dd.getDate()
  d = d < 10 ? '0' + d : d

  return `${y}-${m}-${d}`
}

const diff = (sDate1, sDate2) => {
  let aDate
  let oDate1
  let oDate2
  let iDays
  aDate = sDate1.split('-')
  oDate1 = new Date(`${aDate[1]}-${aDate[2]}-${aDate[0]} `) // 转换为12-18-2006格式
  aDate = sDate2.split('-')
  oDate2 = new Date(`${aDate[1]}-${aDate[2]}-${aDate[0]}`)
  iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24) // 把相差的毫秒数转换为天数
  return iDays
}

const downloadExcelByData = ({ data, fileName }) => {
  const blob = new Blob(['\ufeff' + data], { type: 'text/csv' })
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName)
  } else {
    const element = document.createElement('a')
    element.href = window.URL.createObjectURL(blob)
    element.download = fileName + '.csv'
    element.click()
  }
}

const toChartData = (data, type = 'mon') => {
  var res = {
    categories: [],
    data: []
  }
  data.forEach(item => {
    if (type === 'day') {
      var date = item.countDate.substring(item.countDate.indexOf('-') + 1, item.countDate.length)
      res.categories.push(date)
    } else if (type === 'city') {
      res.categories.unshift(item.name)
    } else {
      res.categories.push(item.code)
    }

    if (type === 'city') {
      res.data.unshift(item.value)
    } else if (type === 'mile') {
      res.data.push((item.count / 1000).toFixed())
    } else if (type === 'time') {
      res.data.push((item.count / 60 / 60 / 1000).toFixed())
    } else {
      res.data.push(item.value)
    }
  })
  return res
}

const STATE_LIST = {
  0: '启用',
  1: '禁用',
  3: '停用'
}
const STATE_MAP = returnJson(STATE_LIST)

const getTreeNodes = nodes => {
  return nodes.map(n => {
    let d = {
      key: n.level === null ? n.id : n.id + '_' + n.level,
      title: n.name,
      id: n.id,
      isMenu: n.isMenu.toString(),
      enName: n.enName,
      name: n.name,
      url: n.url,
      parentId: n.parentId,
      number: n.number,
      level: n.level
    }
    if (n.children) {
      d.children = getTreeNodes(n.children)
    }
    return d
  })
}

const getTreeNodesRole = nodes => {
  return nodes.map(n => {
    let d = {
      key: n.level === null ? n.id : n.id + '_' + n.level,
      title: n.name,
      id: n.id,
      isMenu: n.isMenu.toString(),
      enName: n.enName,
      name: n.name,
      url: n.url,
      parentId: n.parentId,
      number: n.number
    }

    if (n.children) {
      d.children = getTreeNodesRole(n.children)
    }
    return d
  })
}

const getTreeNodesStaff = nodes => {
  return nodes.map(n => {
    let d = {
      value: n.factoryOrAgentId,
      label: n.name
    }

    if (n.children) {
      d.children = getTreeNodesStaff(n.children)
    }
    return d
  })
}

const getTreeNodesRelatedType = nodes => {
  return nodes.map(n => {
    let d = {
      value: n.id,
      label: n.checkType
    }

    if (n.children) {
      d.children = getTreeNodesRelatedType(n.children)
    }
    return d
  })
}

const toStaffTreePer = (per, vendorId = 'vendorId') => {
  let arr = []
  if (per.vendor && per.agent && per.dealer) {
    // 厂家 代理商 经销商
    arr = [vendorId, 'agentId', 'dealerId']
  } else if (per.agent && per.dealer) {
    // 代理商 经销商
    arr = ['agentId', 'dealerId']
  } else if (per.dealer) {
    //  经销商
    arr = ['dealerId']
  }
  return arr
}
export {
  debounce,
  once,
  lock,
  asyncHandler,
  getRandomStr,
  deepClone,
  formatDate,
  getTimeRange,
  timeData,
  fixColumn,
  timesTampsToData,
  throwMessage,
  throwConfirm,
  GetDateStr,
  diff,
  downloadExcelByData,
  toChartData,
  getTreeNodes,
  STATE_LIST,
  STATE_MAP,
  getTreeNodesRole,
  getTreeNodesStaff,
  getTreeNodesRelatedType,
  toStaffTreePer
}
